<template>
  <!-- Contacts table -->
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.viber_senders')"
        :showButton="false"
      />
      <!-- Senders table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="senders"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          :paginator="true"
          v-if="!loading"
          :rows="10"
          :filters="filters"
          v-model:selection="selectedRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} senders"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          :globalFilterFields="['channel', 'country']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
                <Export
                  :dt="dt"
                  :tableData="mappedSenders"
                  :columns="sendersHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="country"
            style="width: 30%"
            :header="$t('xpbx.table-field.country')"
          >
            <template #body="{ data }">
              <div>
                {{ getCountryName(data?.country_id) }}
              </div>
              <TableActions
                :showEdit="false"
                :showIsActive="false"
                :data="data"
                :id="data.id"
                @delete="deleteSelectedRecord"
              />
            </template>
          </Column>
          <Column
            sortable
            field="channel"
            style="width: 20%"
            :header="$t('xpbx.table-field.channel')"
          >
            <template #body="slotProps">
              {{ slotProps?.data?.channel }}
            </template>
          </Column>
          <Column
            sortable
            field="order_status"
            style="width: 30%"
            :header="$t('xpbx.table-field.sender')"
          >
            <template #body="slotProps">
              {{ slotProps?.data?.order_status }}
            </template>
          </Column>
          <Column
            sortable
            field="created_at"
            style="width: 20%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />

        <!-- Dialogs -->
        <Dialog
          v-model:visible="deleteRecordsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
          class="p-fluid relative"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center">{{
              $t("notify.xpbx.message.confirm_delete", {
                delete: `sender ${selectedRecord?.id ? selectedRecord.id : ""}`,
              })
            }}</span>
          </div>
          <template #footer>
            <Button
              text
              :label="$t('xpbx.button.no')"
              @click="deleteRecordsDialog = false"
            />
            <Button
              text
              :label="$t('xpbx.button.yes')"
              @click="deleteSelectedRecords"
            />
          </template>
        </Dialog>

        <!-- Create sender -->
        <Dialog
          v-model:visible="senderDialog"
          :style="{ width: '450px' }"
          :header="$t('xpbx.pages.heading.create_sender')"
          :modal="true"
          :dismissableMask="true"
          class="p-fluid relative"
        >
          <!-- Country -->
          <div class="field mb-2 mt-4 relative">
            <label for="description">{{
              $t("xpbx.labels.select_country")
            }}</label>
            <SelectWithSearch
              v-model="sender.country"
              :options="countryOptions"
              name="country"
              id="country"
              :errors="errors.country"
              :footerLabel="
                $t('label.profile.profileinfo-pwd.body.company-info.country')
              "
            />
            <small class="p-error" v-if="submitted && !!errors?.country?.[0]">{{
              errors?.country?.[0]
            }}</small>
          </div>
          <!-- Message -->
          <div class="field mb-2 mt-4 relative">
            <label for="message">{{ $t("xpbx.labels.message") }}</label>
            <InputText
              id="message"
              type="text"
              v-model="sender.message"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !sender.message }"
            />
            <small class="p-error" v-if="submitted && !!errors?.message?.[0]">{{
              errors?.message?.[0]
            }}</small>
          </div>

          <!-- Channels -->
          <div class="field mb-2">
            <label for="status">{{ $t("xpbx.labels.status") }}</label>
            <form-select
              v-model="sender.channel"
              :options="channels"
              name="status"
              id="status"
              :activeClass="true"
            />
          </div>

          <template #footer>
            <Button
              text
              :label="$t('xpbx.button.cancel')"
              @click="senderDialog = false"
            />
            <Button :label="$t('xpbx.button.save')" text @click="create" />
          </template>
        </Dialog>

        <!-- Edit sender -->
        <Dialog
          v-model:visible="senderEditDialog"
          :style="{ width: '450px' }"
          :header="$t('xpbx.pages.heading.edit_sender')"
          :modal="true"
          :dismissableMask="true"
          class="p-fluid relative custom-dialog-heading"
        >
          <!-- Country -->
          <div class="field mb-2 mt-4 relative">
            <label for="description">{{
              $t("xpbx.labels.select_country")
            }}</label>
            <SelectWithSearch
              v-model="sender.country"
              :options="countryOptions"
              name="country"
              id="country"
              :errors="errors.country"
              :footerLabel="
                $t('label.profile.profileinfo-pwd.body.company-info.country')
              "
            />
            <small class="p-error" v-if="submitted && !!errors?.country?.[0]">{{
              errors?.country?.[0]
            }}</small>
          </div>

          <template #footer>
            <Button
              text
              :label="$t('xpbx.button.cancel')"
              @click="senderDialog = false"
            />
            <Button :label="$t('xpbx.button.save')" text @click="create" />
          </template>
        </Dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import useSenders from "@/modules/xpbx/composables/useSenders";
import { validateCreateSender } from "@/composables/auth/senderValidations";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import { sendersHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "ViberSenders",

  components: {
    PageHeader,
    Loader,
    DateItem,
    Column,
    Button,
    InputText,
    Dialog,
    DataTable,
    Export,
    TableActions,
  },

  setup() {
    const store = useStore();
    const countries = computed(() => store.getters.countries);
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const senderDialog = ref(false);
    const senderEditDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const {
      findSenders,
      deleteSender,
      createSender,
      senders,
      sender,
      loading,
    } = useSenders();

    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    const mappedSenders = computed(() => {
      return senders.value.map((item) => {
        const country = getCountryName(item.country_id);
        return {
          ...item,
          country,
        };
      });
    });

    const viberSenders = computed(() => {
      return senders.value.filter(
        (item) => item.channel === "VIBER" || item.channel === "2"
      );
    });

    const resetSender = () => {
      sender.value = {
        description: "",
        message: "",
        country: "",
        channel: "SMS",
      };
      submitted.value = false;
    };

    const getCountryName = (countriID) => {
      if (!countries.value) return "";
      const country = countries.value.find(
        (country) => country.id === countriID
      );
      return country?.country_name || "";
    };

    const createRecord = async () => {
      submitted.value = true;
      const isValid = validateCreateSender(sender.value);

      if (isValid) {
        const country = countries.value.find(
          (x) => x.country_name === sender.value.country
        );

        const countryID = country.id;

        const formData = {
          message: sender.value.message,
          country_id: `${countryID}`,
          channel: sender.value.channel,
        };

        await createSender(formData);
        senderDialog.value = false;
      }
    };

    // const update = async () => {};

    const create = async () => {
      if (isEdit.value) {
        await update();
      } else {
        await createRecord();
      }
    };

    const openNew = () => {
      isEdit.value = false;
      senderDialog.value = true;
    };

    const closeDialog = (value) => {
      senderDialog.value = value;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteSender(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const channels = [
      { value: "SMS", name: "SMS" },
      { value: "VIBER", name: "VIBER" },
      { value: "TELEGRAM", name: "TELEGRAM" },
    ];

    watch(senderDialog, (value) => {
      if (!value) resetSender();
    });

    onMounted(async () => {
      await findSenders();
    });

    return {
      dt,
      sender,
      loading,
      senders,
      isEdit,
      channels,
      viberSenders,
      mappedSenders,
      errors,
      filters,
      submitted,
      countries,
      senderDialog,
      senderEditDialog,
      scrollHeight,
      countryOptions,
      selectedRecord,
      selectedRecords,
      getCountryName,
      sendersHeaders,
      deleteRecordsDialog,
      // Methods
      create,
      openNew,
      closeDialog,
      createRecord,
      resetSender,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>