import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useSenders() {
  const t = inject("t");
  const store = useStore();
  const sender = ref({
    description: "",
    message: "",
    channel: "SMS",
  });
  const senders = ref([]);
  const loading = ref(true);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND SENDERS
  const findSenders = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/sender");

      if (response?.data) senders.value = response.data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND SENDER
  const findSender = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/sender/${id}`);

      if (response?.data?.length) {
        const templateData = {
          ...response.data[0],
          channel: response.data[0].channel || "SMS",
        };
        template.value = templateData;
      }
    } catch (error) {}
  };

  //   CREATE SENDER
  const createSender = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(`/admin/xpbx/settings/sender`, data);

      if (response?.data?.id) {
        senders.value = [response.data, ...senders.value];
        n("notify.xpbx.create_sender", "success");
      } else {
        n("notify.xpbx.error_create_sender_waiting", "success");
      }
    } catch (error) {
      n("notify.xpbx.error_create_sender");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE SENDER
  const deleteSender = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(`/admin/xpbx/settings/sender/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_sender", "success");
        senders.value = senders.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_sender");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_sender");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    sender,
    senders,
    loading,
    findSender,
    findSenders,
    createSender,
    deleteSender,
  };
}
